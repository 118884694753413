<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Photo information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="photo.title"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Description" label-position="inside">
                        <b-input type="textarea" v-model="photo.description"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="photo.main">Primary Day Photo</b-switch>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        name: 'DayPhotoForm',
        props: {
            photo: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList}
    };
</script>
