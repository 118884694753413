<template>
    <div>
        <card-header title="New Day Photo" icon="fa-plus"/>

        <card-body>
            <card-list>
                <day-photo-form :card="card" :photo="newPhoto"/>
                <container-list-item v-if="newPhoto.file">
                    <data-row title="File">{{newPhoto.file_name}}</data-row>
                    <data-row title="Hash">{{newPhoto.file}}</data-row>
                    <data-row title="Type">{{newPhoto.file_mime}}</data-row>
                </container-list-item>
                <container-list-item padded>
                    <file-pond
                        name="file"
                        ref="pond"
                        label-idle="Drop or click to add photo..."
                        :allow-multiple="false"
                        :allowRevert="false"
                        accepted-file-types="image/jpeg, image/png, image/gif"
                        :files="files"
                        @init="handleFilePondInit"
                        @processfile="handleFilePondProcess"
                        :server="{
                                    url: apiUrl,
                                    timeout: 7000,
                                    process: {
                                        url: 'api/day_photos/upload',
                                        method: 'POST',
                                        headers: {
                                            'Authorization': token
                                        },
                                        withCredentials: false
                                    }
                                }"
                    />
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newPhoto.file" @click="submit"><i class="fas fa-plus mr-3"></i>New Photo</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '@/TIER/components/CardHeader';
    import CardContainer from '@/TIER/components/CardContainer';
    import CardFooter from "@/TIER/components/CardFooter";
    import CardList from "@/TIER/components/CardList";
    import CardBody from "@/TIER/components/CardBody";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import DataRow from "@/TIER/components/DataRow";

    import DayPhotoForm from "@/cards/trips/DayPhotoForm";

    import {mapGetters} from "vuex";
    import {client as http} from '@/http_client';

    // Import Vue FilePond
    import vueFilePond from 'vue-filepond';

    // Import FilePond styles
    import 'filepond/dist/filepond.min.css';

    // Import FilePond plugins
    // Please note that you need to install these plugins separately

    // Import image preview plugin styles
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    // Import image preview and file type validation plugins
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';


    // Create component
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        props: ['card', 'props'],
        components: {DayPhotoForm, DataRow, FilePond, ContainerListItem, CardBody, CardList, CardFooter, CardContainer, CardHeader},
        data: function() {
            return {
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/',
                newPhoto: {
                    day_id: null,
                    file: null,
                    file_name: null,
                    file_mime: null
                },
                files: []
            };
        },
        computed: {
            ...mapGetters(['token']),
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/day_photos/', this.newPhoto, {force: true}).then(response => {
                    this.$reloadCard('day', {dayId: this.props.dayId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            },
            handleFilePondInit: function() {
                // console.log('FilePond has initialized');
                // FilePond instance methods are available on `this.$refs.pond`
            },

            handleFilePondProcess: function(err, response) {
                // Todo this doesn't handle errors
                // FilePond:processfile - Finished processing a file, if the detail object contains an error property, something went wrong

                let file = JSON.parse(response.serverId);

                this.newPhoto.file = file.file_name;
                this.newPhoto.file_name = file.file_original_name;
                this.newPhoto.file_mime = file.file_mime;

                setTimeout(function() {
                    this.$refs.pond.removeFile(file.id);
                }.bind(this), 3000);

            },
        },
        mounted() {
            this.newPhoto.day_id = this.props.dayId;
            this.$saveAlert(true, 'Are you done creating this new photo? Unsaved changes will be lost.');
        }
    };
</script>

<style>
    .filepond--credits {
        display: none !important;
    }
</style>
